// These constants are used in production code and should not be changed for tests

export const ANNUAL_MEMBERSHIP_SKU = '940-000003-312';
export const ANNUAL_MEMBERSHIP_KEY = 'whoop-membership-12m';
export const TWENTYFOUR_MONTH_MEMBERSHIP_SKU = '940-000003-124';
export const TWENTYFOUR_MONTH_MEMBERSHIP_KEY = 'whoop-membership-24m';
export const MONTHLY_MEMBERSHIP_SKU = '940-000003-121';
export const MONTHLY_MEMBERSHIP_KEY = 'whoop-membership-mtm';
export const TRIAL_MEMBERSHIP_SKU = '940-000003-120';
export const TRIAL_MEMBERSHIP_KEY = 'whoop-membership-1m-trial';
